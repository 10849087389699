import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileInfo } from '../../apis/fileinfo';
import config from '../../env.json';
import { setFromNumber, setNumber,setOtpValue, setPdfId, setRecepientName,setFormId, setMsgChannel} from '../../app/slice/pdfslice';
import VerificationModal from '../../components/Verification/VerificationModal'

import './NumberInput.css'

export default function NumberInput() {
  const dispatch=useDispatch()
  const [disableButton, setDisableButton] = useState(true);
  const [newMobileNumber, setNewMobileNumber] = useState<any>();
  const [newMobileNumberObject, setNewMobileNumberObject] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [error,setError]=useState(false)
 
  /* istanbul ignore next */
  const getNewMobileNumber=(e:any)=>{ 
    if (/^\d+$/.test(e.target.value.trim())) {
      setNewMobileNumber(e.target.value)
    }else{
      e.target.value = ""
      setNewMobileNumber(e.target.value)
    }
   
    if(e.target.value.length <4){
      setDisableButton(true)
    }else{
      setDisableButton(false)
     
    }
    
  }
  /* istanbul ignore next */
  const numberVarification=()=>{
    
    if(newMobileNumber.length <4){
      
      setDisableButton(true)
    }else{
      if(window.location.pathname.length>1){
        
      const pathParts = window.location.pathname.split("/")
      const countryCode = pathParts[1]; 
      const id = pathParts[2].split(".")[0]; 
      const zoneDomain = config.REACT_APP_FE +"_"+ countryCode;
      localStorage.setItem("ZoneNm",zoneDomain)

      dispatch(setPdfId(id))
      fileInfo(id,newMobileNumber,(res:any)=>{
        console.log(res)
        if(res){
          dispatch(setNumber(res.ToNumber))
          dispatch(setOtpValue(res.OTP))
          dispatch(setFromNumber(res.FromNumber))
          dispatch(setRecepientName(res.RecepientName?res.RecepientName:''))
          dispatch(setMsgChannel(res.MsgChannel?res.MsgChannel:''))
          dispatch(setFormId(res.FormId))
          if(res.ToNumber.substring(res.ToNumber.length-4)==newMobileNumber){
            setDisableButton(false)
            setShowModal(true)
            setError(false)
          }
          else{
            setError(true)
            setDisableButton(true)
          }
        }
      })

      }
    }
  }
  
  return (
    <div className='input-wrapper'>
      {showModal?
        <VerificationModal show={showModal} newMobileNumber={newMobileNumber} handleClose={()=>{setShowModal(false);}}/>:null}
        <div className='number-input-container'>
        <input className='mobile-number-input' data-testid="mobile-numberInput" maxLength={4} value={newMobileNumber} onChange={getNewMobileNumber} placeholder='Enter last 4 digit of your mobile number' required/>
        {error?<span className='error'>Please enter valid digits.</span>:null}
        </div>
        
        <div className='btn-wrapper'>
        <button className={disableButton?'btn-primary disable-btn':'btn-primary'}
        //  style={{ opacity: disableButton ? 1 : 0.4 }}
        disabled={disableButton}
         onClick={()=>{numberVarification()}}>Verify</button>
        </div>
    </div>
  )
}
