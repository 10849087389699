import axios from 'axios'
import config from '../env.json';

export const fileInfo=async(id:any,newMobileNumber:any,callback:any)=>{
    try {
      let newMobileNumberObject:any = {
        "lastFourDigit": newMobileNumber
    }

     await   axios.post(
            config.REACT_APP_FORMSAPI+"/formrequestotp/"+id,newMobileNumberObject,
            {
              headers: {
                "Domain": localStorage.getItem("ZoneNm")
            }
          }
          ).then((res:any)=>{
            if(res)
            callback(res.data);
            else
            callback(null)
          }).catch((err:any)=>callback(null))
          
    } catch (error) {
        callback(null)
    }

}

export const formstatus=async(formdata:any)=>{
  try {
   await   axios.post(
          `${config.REACT_APP_FORMSAPI}`+"/formRequeststatus",formdata,
          {
            headers: {
              "Domain": localStorage.getItem("ZoneNm")
          }
        }
        ).then((res:any)=>{
          console.log(res)
        }).catch((err:any)=>console.log(err))
        
  } catch (error) {
     console.log(error)
  }

}


export const saveToBlob=async(formdata:any,callback:any)=>{
  try {
   await   axios.post(
          `${config.REACT_APP_FORMSAPI}`+"/savetoblob",formdata,
          {
            headers: {
              "Domain": localStorage.getItem("ZoneNm")
          }
        }
        ).then((res:any)=>{
          console.log(res)
          callback(res.data)
        }).catch((err:any)=>callback(null))
        
  } catch (error) {
     console.log(error)
     callback(null)
  }
}


export const sendMessageOnSave=async (data:any) => {
    try{
      await  axios.post(
        `${config.REACT_APP_MESSAGE_API}`+"/oooAutoResponse",data,
        {
          headers: {
            "Domain": localStorage.getItem("ZoneNm")
        }
      }
      ).then((res:any)=>{
        console.log(res)
      }).catch((err:any)=>console.log(err))
    }catch(e){
      console.log(e)
    }
}


export const getFormDeliveryOption=async(formId:any,callback:any)=>{
  try{
    await  axios.get(
      `${config.REACT_APP_FORMSAPI}`+"/formDeliveriesByRequestId/"+formId,
      {
        headers: {
          "Domain": localStorage.getItem("ZoneNm")
      }
    }
    ).then((res:any)=>{
    if(res){
      callback(res.data);
    }
    }).catch((err:any)=>callback(null))
  }catch(e){
    console.log(e)
    callback(null);
  }
}

export const notificationWithCustomUrl=async (url:any,params:any,callback:any) => {
  try{
    await  axios.post(
     url,params
    ).then((res:any)=>{
    if(res){
      callback(res.data);
    }
    }).catch((err:any)=>callback(null))
  }catch(e){
    console.log(e)
    callback(null);
  }
}

export const formInfo=async(id:any,callback:any)=>{

try{
  await  axios.get(
    config.REACT_APP_FORMSAPI+'/formscompletewithoutauth/'+id,
    {
      headers: {
        "Domain": localStorage.getItem("ZoneNm")
    }
  }
  ).then((res:any)=>{
  if(res){
    callback(res.data);
  }
  }).catch((err:any)=>callback(null))
}catch(e){
  console.log(e)
  callback(null);
}
}